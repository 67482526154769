import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Automagically pull and restart your program whenever git changes are made.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`Download an executable for your platform from `}<a parentName="p" {...{
        "href": "https://github.com/programmer5000-com/github-auto-pull/releases"
      }}>{`the releases page`}</a>{` or run `}<inlineCode parentName="p">{`npm i -g @programmer5000/github-auto-pull`}</inlineCode>{`.
Then create the script you want to run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`echo "echo test" > script.sh
chmod +x script.sh
./github-auto-pull script.sh
`}</code></pre>
    <p>{`If you get an EACCES error, try this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`sudo apt-get install libcap2-bin
sudo setcap cap_net_bind_service=+ep \`readlink -f \\\`which node\\\`\`
`}</code></pre>
    <p>{`github-auto-pull binds on port 80, which can usually only be bound by root. These commands allow node to bind on port 80. See also `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/a/23281417/6560716"
      }}>{`this StackOverflow post`}</a>{`.`}</p>
    <p>{`Otherwise, stop github-auto-pull.`}</p>
    <p>{`Then go into your desired github repo. Create a webhook, setting your IP address as the URL. Make sure to set the content type to `}<inlineCode parentName="p">{`application/json`}</inlineCode>{`. Then generate a random string and set that as your secret for the webhook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./github-auto-pull script.sh INSERT_SECRET_HERE
`}</code></pre>
    <p>{`Save the webhook and your console should tell you that you got a ping.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      